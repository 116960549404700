import { Card } from "react-bootstrap";
import drewPic from "./drew_pic.jpg";

function AboutCard() {
	return (
    <Card className="about-me-class">
      <Card.Img className="rounded-circle mb-2" src={drewPic} />
      <Card.Body>
        <Card.Text>I'm Drew, and I work in software. I'm based in the Boston area, but I'm originally from Birmingham, AL via Tampa, FL. Other than tech, I'm interested in sports and cooking.</Card.Text>
        <Card.Text>This site is a place for me to post links to and write blurbs about stuff I find interesting!</Card.Text>
        <Card.Link href="https://www.linkedin.com/in/drew-berry-3960a7148/">LinkedIn</Card.Link>
        <Card.Link href="https://github.com/drewb191">github</Card.Link>
      </Card.Body>
    </Card>
	);
};

export default AboutCard;
