// Post1.js

import { Card } from "react-bootstrap";

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

function Post({contentUrl, imgSource, contentTitle, contentSummary}) {
  return (
    <a style={{ cursor: 'pointer' }} onClick={() => openInNewTab(contentUrl) }>
      <Card className="mb-4">
        <Card.Img
          variant="top"
          src={imgSource}
          className="post-card-img"
        />
        <Card.Body>
          <Card.Title>{contentTitle}</Card.Title>
          <Card.Text>
            {contentSummary}
          </Card.Text>
        </Card.Body>
      </Card>
    </a>
	);
};

export default Post;
