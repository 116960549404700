// Posts.js

import React from "react";
import PaginatedItems from "./Pagination";
import AboutCard from "./AboutCard";
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col } from 'react-bootstrap';


const Posts = () => {
	return (
		<Container>
			<Row className="justify-content-between">
				<Col md={3} className="mt-4 float-right">
          <AboutCard />
				</Col>
				<Col md={9} className="mb-4 mt-4">
          <PaginatedItems itemsPerPage={4} />
				</Col>
			</Row>
		</Container>
	);
};

export default Posts;
